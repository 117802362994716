<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
		>
			<template #header-wrapper>
				<ModalHeaderWrapper :title="title" @onClose="close" />
			</template>
			<template #default>
				<CRow>
					<CCol>
						<FormGetBranch
							ref="store-form"
							:selected-list="localSelectedList"
							:action-button-text="addButtonText"
							@onGetProduct="handleListChange"
						>
							<template #no-item>
								<div class="no-item d-flex text-center" data-test-id="no-item">
									<div class="d-block w-100">
										<div class="font-weight-bolder color-black-45">
											No Branch yet
										</div>
										<div class="typo-body-2 color-black-45">
											Add "Branch ID" in order to set condition.
										</div>
									</div>
								</div>
							</template>
						</FormGetBranch>
					</CCol>
				</CRow>
			</template>
			<template #footer>
				<BaseModalFooter
					:total-select="localSelectedList.length"
					@onClear="handleClear"
					@onCancel="close"
					@onConfirm="handleSubmit"
				/>
			</template>
		</CModal>
		<BaseModalConfirm
			v-if="needConfirmation"
			ref="modal-confirm"
			:title="titleConfirm"
			:description="descriptionConfirm"
			@onConfirm="handleConfirm"
		/>
	</div>
</template>


<script>
import cloneDeep from 'lodash/cloneDeep';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import BaseModalFooter from '@/components/BaseModalFooter.vue';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import FormGetBranch from '@/components/FormGetBranch.vue';

export default {
	name: 'ModalBranch',
	components: {
		ModalHeaderWrapper,
		BaseModalFooter,
		BaseModalConfirm,
		FormGetBranch,
	},
	props: {
		title: {
			type: String,
			default: 'Add branch',
		},
		titleConfirm: {
			type: String,
			default: null,
		},
		descriptionConfirm: {
			type: String,
			default: null,
		},
		needConfirmation: {
			type: Boolean,
			default: false,
		},
		selectedList: {
			type: Array,
			default: () => [],
		},
		addButtonText: {
			type: String,
			default: 'Add branch',
		},
	},
	data() {
		return {
			isShow: false,
			localSelectedList: [...this.selectedList],
		};
	},
	methods: {
		open() {
			this.isShow = true;
			this.resetState();
		},
		close() {
			this.isShow = false;
		},
		handleClear() {
			this.localSelectedList = [];
		},
		submit() {
			this.isShow = false;
			this.$emit('onConfirm', this.localSelectedList);
		},
		resetState() {
			this.localSelectedList = cloneDeep(this.selectedList);
			this.$refs['store-form'].resetState();
		},
		handleListChange(list) {
			this.localSelectedList = list;
		},
		handleSubmit() {
			if (this.needConfirmation) {
				this.$refs['modal-confirm'].open();
			} else {
				this.submit();
			}
		},
		handleConfirm() {
			this.submit();
		},
	},
};
</script>
