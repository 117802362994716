<template>
	<BaseLoading v-if="isLoading" />
	<div v-else>
		<div class="d-flex align-items-center justify-content-between mb-3">
			<h4 class="title">{{ title }}</h4>
			<div
				v-if="isEditable"
				class="text-right align-self-start"
			>
				<CButton
					color="secondary"
					class="btn btn-secondary text-nowrap"
					@click.prevent="$refs['modal-branch'].open()"
				>
					Add branch
				</CButton>
			</div>
		</div>

		<div v-if="selectedBranches.length" class="branch-list">
			<ul>
				<li
					v-for="branch in selectedBranches"
					:key="branch.id"
					class="branch-list-item typo-body-2"
				>
					<strong>{{ branch.com7BranchId }}:</strong> {{ branch.nameEN }}
				</li>
			</ul>
		</div>
		<BaseNoItemBanner
			v-else
			:text="emptyText"
		/>

		<ModalBranch
			ref="modal-branch"
			:selected-list="selectedBranchItecIds"
			data-test-id="modal-branch"
			@onConfirm="handleBranchListChange"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ModalBranch from '@/components/ModalBranch.vue';

export default {
	name: 'AvailableBranch',
	components: {
		ModalBranch,
	},
	props: {
		isEditable: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: 'Available branch',
		},
		emptyText: {
			type: String,
			default: 'There are no available branch for this setting',
		},
		branchIds: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isLoading: true,
			selectedBranches: [],
		};
	},
	computed: {
		...mapGetters({
			allBranchList: 'warehouses/warehouseList',
		}),
		selectedBranchItecIds() {
			return this.selectedBranches.map((branch) => branch.com7BranchId);
		},
	},
	async created() {
		// Get all branch list
		await this.getWarehouseList({ per_page: 'all' });

		// Set local selected branches from prop 'branchIds'
		this.selectedBranches = this.allBranchList
			.filter((branch) => this.branchIds.includes(branch.id));

		this.isLoading = false;
	},
	methods: {
		...mapActions({
			getWarehouseList: 'warehouses/getWarehouseList',
		}),
		handleBranchListChange(branchItecIds = []) {
			this.selectedBranches = this.allBranchList
				.filter((item) => branchItecIds.includes(item.com7BranchId));

			const selectedBranchIds = this.selectedBranches.map((branch) => branch.id);
			this.$emit('update:branchIds', selectedBranchIds);
		},
	},
};
</script>

<style lang="scss" scoped>
	.branch-list {
		overflow-y: auto;
		min-height: rem(60);
		max-height: rem(468);
		padding: rem(20) rem(16) rem(8);
		border-radius: rem(4);
		border: solid 1px $color-gray-400;

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			flex: 1;
		}

		// .branch-list-item
		&-item {
			border-radius: rem(4);
			background-color: $color-gray-300;
			color: $color-black-90;
			display: inline-block;
			padding: 0 rem(6);
			margin-right: rem(12);
			margin-bottom: rem(12);
		}
	}
</style>
